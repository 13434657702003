<template>
  <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onSearch="onSearch"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
  >
  </list-template>
</template><script>
import { tableListMixin } from '@/utils/mixins'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  mixins: [tableListMixin],
  methods: {
    onBeforeSearch(val) {
      const {date} = val
      this.search.student_name = val.student_name
      this.search.student_no = val.student_no
      if (date) {
        const [date_start, date_end] = date
        this.search.date_start = date_start
        this.search.date_end = date_end
      } else {
        this.search.date_start = ''
        this.search.date_end = ''
      }
    },
    getData() {
      this.getDataApi(`/api2/api/memorandum/index/${this.type ? 'teacher' : 'student'}`)
    },
    handleClick(row) {
      this.$router.push(`./show?id=${row.id}`)
    }
  },
  data() {
    const obj = {
      '/settings/usage-record/teacher/list': 1,
      '/settings/usage-record/student/list': 0
    }
    const type = obj[this.$route.path] || 0


    let tableConfig = [
      {
        prop: 'name',
        label: '姓名',
        width: '100rem'
      },
      {
        prop: 'username',
        label: type ? '账号' : '学号',
        width: '200rem'
      },
      {
        prop: 'created_at',
        label: '创建时间',
        width: '180rem'
      },
      {
        prop: 'memorandum_text',
        label: '备忘内容'
      },
      {
        label: '操作',
        width: '120rem',
        handle: true,
        render() {
          return ['查看']
        }
      }
    ];
    if (type === 0)tableConfig.splice(3,0,{
      prop: 'memorandum_title',
      label:"标题"
    })
    return {
      type,
      searchConfig: [],
      tableConfig
    }
  },
  created() {
    this.searchConfig = this.type == 1 ?
        [
          {
            prop: 'date',
            tag: 'datePicker',
            property: {
              size: 'small',
              type: 'daterange',
              valueFormat: 'yyyy-MM-dd',
              startPlaceholder: '开始日期',
              endPlaceholder: '结束日期'
            }
          }
        ] :
        [
          {
            prop: 'student_name',
            placeholder: '搜索学生姓名'
          },
          {
            prop: 'student_no',
            placeholder: '搜索学生学号'
          },
          {
            prop: 'date',
            tag: 'datePicker',
            property: {
              size: 'small',
              type: 'daterange',
              valueFormat: 'yyyy-MM-dd',
              startPlaceholder: '开始日期',
              endPlaceholder: '结束日期'
            }
          }
        ]
  }
}
</script>
